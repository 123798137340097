.checkbox {
    display: flex;
    border-width: 1px;
    border-radius: 5px;
    border-color: black;
    font-size: 17px;
    padding: 8px;
    align-items: center;
    justify-content: center;
}

.main_container {
    position: absolute;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: space-around;
    border-width: 1px;
    border-radius: 8px;
    border-color: black;
    height: 220px;
}