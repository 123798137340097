.main_div {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.under_header_div {
    display: flex;
    flex: 1;
    flex-direction: row;
}
.objects_container_admin {
    display: flex;
    flex-direction: row;
    width: calc(100vw - 300px);
}
.objects_container_operator {
    display: flex;
    flex-direction: row;
    width: 100vw;
}
.user_container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 15%;
    min-width: 300px;
    border-right-width: 1px;
    border-color: #a9a9a9;
}
.stat_container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.stat_history_container {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
}
.stat_history_container ul {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
}
.stat_history_container li {
    margin-bottom: 2px;
    font-size: 17px;
    flex: 1;
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.user_name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    border-bottom-width: 1px;
    padding-bottom: 20px;
    padding-top: 20px;
    border-color: #a9a9a9;
}
.stat_name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px
}

.call_container {
    padding: 20px 20px 0 20px;
    display: flex;
    flex: 1;
    flex-direction: row;
}